<script setup>
import { open as openCookieConsent } from "@/plugins/cookie-consent";
const { t: $t } = useI18n();
const route = useRoute();

const { messages } = useMessageStore();
const loadingStore = useLoadingStore();

const title = computed(() => {
  let titleParts = [];
  if (route?.meta?.title) {
    // @ts-ignore
    titleParts.push($t(route.meta.title));
  }
  const context = useContextStore();
  if (context.event_participation) {
    titleParts.push(context.event_participation.participation_team_name);
  } else if (context.organization) {
    titleParts.push(context.organization.name);
  }
  if (context.event) {
    titleParts.push(context.event?.name);
  }
  titleParts.push($t("document.project_name"));
  document.title = titleParts.join(" | ");
  return titleParts.join(" | ");
});

watch(title, (title) => (document.title = title));
</script>
<template>
  <v-app ref="app" full-height>
    <v-snackbar
      v-for="(message, index) in messages"
      :key="index"
      :color="message.type || 'info'"
      :model-value="true"
      location="top"
      :timeout="message.timeout || 7000"
      class="mt-5"
    >
      {{ message.key ? $t(message.key, message.params) : message.message }}
    </v-snackbar>
    <v-overlay :model-value="loadingStore.loading" opacity="0.7" z-index="2000" class="justify-center align-center">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <router-view />
    <v-footer
      dark
      absolute
      app
      color="#A6ADB4"
      class="text-caption text-white pt-2"
      :height="$vuetify.display.mdAndUp ? '60px' : '220px'"
    >
      <v-row no-gutters>
        <v-col cols="12" md="auto" class="text-center mx-3 text-md-left">
          <img src="@/assets/alvarum-white.svg" height="20px" />
        </v-col>
        <v-col cols="12" md="auto" class="text-center mx-3 text-md-right">
          &#169; {{ new Date().getFullYear() }}
          <a href="https://www.alvarum.com" class="text-white font-weight-bold text-decoration-none">Alvarum</a>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="auto" class="text-center mx-3">
          <a :href="$t('footer.help.link')" class="text-white text-decoration-none">{{ $t("footer.help.text") }}</a>
        </v-col>
        <v-col cols="12" md="auto" class="text-center mx-3">
          <a :href="$t('footer.terms_and_conditions.link')" class="text-white text-decoration-none">
            {{ $t("footer.terms_and_conditions.text") }}
          </a>
        </v-col>
        <v-col cols="12" md="auto" class="text-center mx-3">
          <a :href="$t('footer.privacy.link')" class="text-white text-decoration-none">
            {{ $t("footer.privacy.text") }}
          </a>
        </v-col>
        <v-col cols="12" md="auto" class="text-center mx-3">
          <a :href="$t('footer.imprint.link')" class="text-white text-decoration-none">
            {{ $t("footer.imprint.text") }}
          </a>
        </v-col>
        <v-col cols="12" md="auto" class="text-center mx-3">
          <a @click="openCookieConsent" class="text-white text-decoration-none">{{ $t("footer.cookies") }}</a>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-footer>
    <router-view name="dialog"></router-view>
  </v-app>
</template>
<style>
html,
body {
  min-height: 100vh;
}

.show-only-on-hover {
  opacity: 0;
}

td:hover .show-only-on-hover {
  opacity: 1;
}

#app {
  background: #ebeded;
  min-height: 100vh;
}

.v-application--wrap {
  min-height: 100% !important;
  min-height: -webkit-fill-available !important;
}

.v-navigation-drawer--is-mobile {
  height: 100% !important;
  height: -webkit-fill-available !important;
}

.header-toolbar {
  height: 48px !important;
}
</style>
