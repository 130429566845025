export const routes = [
  {
    path: '/:not_found(.*)',
    name: '/[...not_found]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/[...not_found].vue'),
    /* no children */
    meta: {
      "public": true,
      "title": "document.notFound"
    }
  },
  {
    path: '/admin',
    /* internal name: '/admin' */
    /* no component */
    children: [
      {
        path: '',
        name: '/admin/',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/index.vue'),
        /* no children */
      },
      {
        path: 'event/:eventId',
        /* internal name: '/admin/event.[eventId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/event.[eventId]/',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/index.vue'),
            /* no children */
          },
          {
            path: 'advanced',
            name: '/admin/event.[eventId]/advanced',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/advanced.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'b2b-group-params',
            name: '/admin/event.[eventId]/b2b-group-params',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/b2b-group-params.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'charity-group-params',
            name: '/admin/event.[eventId]/charity-group-params',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/charity-group-params.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'checkin',
            name: '/admin/event.[eventId]/checkin',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/checkin.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'dashboard',
            name: '/admin/event.[eventId]/dashboard',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/dashboard.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'design-settings',
            name: '/admin/event.[eventId]/design-settings',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/design-settings.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'discount-code/:discountCodeId?',
            name: '/admin/event.[eventId]/discount-code.[[discountCodeId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/discount-code.[[discountCodeId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'discount-codes',
            name: '/admin/event.[eventId]/discount-codes',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/discount-codes.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-contents',
            name: '/admin/event.[eventId]/email-contents',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-contents.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-contents/:id',
            name: '/admin/event.[eventId]/email-contents.[id]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-contents.[id].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-contents/:id/preview',
            name: '/admin/event.[eventId]/email-contents.[id].preview',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-contents.[id].preview.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-contents/:id/report',
            name: '/admin/event.[eventId]/email-contents.[id].report',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-contents.[id].report.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-contents/:id/settings',
            name: '/admin/event.[eventId]/email-contents.[id].settings',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-contents.[id].settings.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-templates',
            name: '/admin/event.[eventId]/email-templates',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-templates.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'email-templates/:event_email_template_content_id/:template_id',
            name: '/admin/event.[eventId]/email-templates.[event_email_template_content_id].[template_id]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/email-templates.[event_email_template_content_id].[template_id].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-details',
            name: '/admin/event.[eventId]/event-details',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-details.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-participation/:organizationType/:organizationId',
            name: '/admin/event.[eventId]/event-participation.[organizationType].[organizationId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-participation.[organizationType].[organizationId].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-participation/:organizationType/:organizationId/edit',
            name: '/admin/event.[eventId]/event-participation.[organizationType].[organizationId].edit',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-participation.[organizationType].[organizationId].edit.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-participation/:organizationType/:organizationId/settings',
            name: '/admin/event.[eventId]/event-participation.[organizationType].[organizationId].settings',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-participation.[organizationType].[organizationId].settings.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-participations/:organizationType',
            name: '/admin/event.[eventId]/event-participations.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-participations.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-ticket/:eventTicketId?',
            name: '/admin/event.[eventId]/event-ticket.[[eventTicketId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-ticket.[[eventTicketId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'event-tickets',
            name: '/admin/event.[eventId]/event-tickets',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/event-tickets.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'payment-methods/:organizationType',
            name: '/admin/event.[eventId]/payment-methods.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/payment-methods.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'register-orders',
            name: '/admin/event.[eventId]/register-orders',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/register-orders.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'register-orders/:registerOrderId',
            name: '/admin/event.[eventId]/register-orders.[registerOrderId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/register-orders.[registerOrderId].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'registration-form-fields',
            name: '/admin/event.[eventId]/registration-form-fields',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/registration-form-fields.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'registration-form/:registrationId?',
            name: '/admin/event.[eventId]/registration-form.[[registrationId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/registration-form.[[registrationId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'registrations',
            name: '/admin/event.[eventId]/registrations',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/registrations.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'registrations/:registrationId',
            name: '/admin/event.[eventId]/registrations.[registrationId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/registrations.[registrationId].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-categories/:organizationType',
            name: '/admin/event.[eventId]/sales-categories.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-categories.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-discount-code/:organizationType/:salesDiscountCodeId?',
            name: '/admin/event.[eventId]/sales-discount-code.[organizationType].[[salesDiscountCodeId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-discount-code.[organizationType].[[salesDiscountCodeId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-discount-codes/:organizationType',
            name: '/admin/event.[eventId]/sales-discount-codes.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-discount-codes.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-order/:orderId',
            name: '/admin/event.[eventId]/sales-order.[orderId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-order.[orderId].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-orders/:organizationType',
            name: '/admin/event.[eventId]/sales-orders.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-orders.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-product/:organizationType/:salesProductId?',
            name: '/admin/event.[eventId]/sales-product.[organizationType].[[salesProductId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-product.[organizationType].[[salesProductId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'sales-products/:organizationType',
            name: '/admin/event.[eventId]/sales-products.[organizationType]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/sales-products.[organizationType].vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'social-analytics',
            name: '/admin/event.[eventId]/social-analytics',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/social-analytics.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          },
          {
            path: 'website-integration',
            name: '/admin/event.[eventId]/website-integration',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/event.[eventId]/website-integration.vue'),
            /* no children */
            meta: {
              "layout": "AdminEvent.layout"
            }
          }
        ],
      },
      {
        path: 'organizer/:organizerId',
        /* internal name: '/admin/organizer.[organizerId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/admin/organizer.[organizerId]/',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/index.vue'),
            /* no children */
          },
          {
            path: 'contact-organizations/:modelKindId/:contactOrganizationId?',
            name: '/admin/organizer.[organizerId]/contact-organizations.[modelKindId].[[contactOrganizationId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/contact-organizations.[modelKindId].[[contactOrganizationId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'contact-organizations/:modelKindId/:contactOrganizationId?/form',
            name: '/admin/organizer.[organizerId]/contact-organizations.[modelKindId].[[contactOrganizationId]].form',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/contact-organizations.[modelKindId].[[contactOrganizationId]].form.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'contacts',
            name: '/admin/organizer.[organizerId]/contacts',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/contacts.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'contacts-organizations',
            name: '/admin/organizer.[organizerId]/contacts-organizations',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/contacts-organizations.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'contacts/:modelKindId/:contactId?',
            name: '/admin/organizer.[organizerId]/contacts.[modelKindId].[[contactId]]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/contacts.[modelKindId].[[contactId]].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'contacts/:modelKindId/:contactId?/form',
            name: '/admin/organizer.[organizerId]/contacts.[modelKindId].[[contactId]].form',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/contacts.[modelKindId].[[contactId]].form.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'email-template-entries',
            name: '/admin/organizer.[organizerId]/email-template-entries',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/email-template-entries.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'email-template-entries/:id',
            name: '/admin/organizer.[organizerId]/email-template-entries.[id]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/email-template-entries.[id].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'email-template-entries/:id/preview',
            name: '/admin/organizer.[organizerId]/email-template-entries.[id].preview',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/email-template-entries.[id].preview.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'events',
            name: '/admin/organizer.[organizerId]/events',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/events.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'events/kind/:modelKindId/new',
            name: '/admin/organizer.[organizerId]/events.kind.[modelKindId].new',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/events.kind.[modelKindId].new.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'model-fields',
            name: '/admin/organizer.[organizerId]/model-fields',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/model-fields.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'model-fields/:model',
            name: '/admin/organizer.[organizerId]/model-fields.[model]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/model-fields.[model].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'organization/:type/:organizationId',
            name: '/admin/organizer.[organizerId]/organization.[type].[organizationId]',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/organization.[type].[organizationId].vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'organization/:type/:organizationId/edit',
            name: '/admin/organizer.[organizerId]/organization.[type].[organizationId].edit',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/organization.[type].[organizationId].edit.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'sales-orders',
            name: '/admin/organizer.[organizerId]/sales-orders',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/sales-orders.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          },
          {
            path: 'users',
            name: '/admin/organizer.[organizerId]/users',
            component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizer.[organizerId]/users.vue'),
            /* no children */
            meta: {
              "layout": "AdminOrganizer.layout"
            }
          }
        ],
      },
      {
        path: 'organizers',
        name: '/admin/organizers',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizers.vue'),
        /* no children */
        meta: {
          "layout": "AdminApp.layout"
        }
      },
      {
        path: 'organizers/:organizerId',
        name: '/admin/organizers.[organizerId]',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/organizers.[organizerId].vue'),
        /* no children */
        meta: {
          "layout": "AdminApp.layout"
        }
      },
      {
        path: 'users',
        name: '/admin/users',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/admin/users.vue'),
        /* no children */
        meta: {
          "layout": "AdminApp.layout"
        }
      }
    ],
  },
  {
    path: '/backoffice',
    name: '/backoffice',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/backoffice.vue'),
    alias: ["/","/onboarding"],
    /* no children */
    meta: {
      "public": true,
      "allow_fundraising_auth": true
    }
  },
  {
    path: '/event/:eventId',
    /* internal name: '/event.[eventId]' */
    /* no component */
    children: [
      {
        path: 'charities/search',
        name: '/event.[eventId]/charities.search',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/charities.search.vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true,
          "display_event_name": true
        }
      },
      {
        path: 'register/confirmation/:orderId?/:registrationId',
        name: '/event.[eventId]/register.confirmation.[[orderId]].[registrationId]',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/register.confirmation.[[orderId]].[registrationId].vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true,
          "display_event_name": true
        }
      },
      {
        path: 'registration-recovery',
        name: '/event.[eventId]/registration-recovery',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/registration-recovery.vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true
        }
      },
      {
        path: 'routing-subscribe-charity',
        name: '/event.[eventId]/routing-subscribe-charity',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/routing-subscribe-charity.vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true,
          "display_event_name": true,
          "b2b_help_chat": true
        }
      },
      {
        path: 'subscribe-charity/:charityId?',
        name: '/event.[eventId]/subscribe-charity.[[charityId]]',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/subscribe-charity.[[charityId]].vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "display_event_name": true,
          "b2b_help_chat": true,
          "allow_fundraising_auth": true,
          "prefer_fundraising_auth": true
        }
      },
      {
        path: 'subscribe/:organizationId?',
        name: '/event.[eventId]/subscribe.[[organizationId]]',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/subscribe.[[organizationId]].vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true,
          "display_event_name": true,
          "b2b_help_chat": true
        }
      },
      {
        path: 'subscribe/error/:organizationId/:salesOrderId',
        name: '/event.[eventId]/subscribe.error.[organizationId].[salesOrderId]',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/event.[eventId]/subscribe.error.[organizationId].[salesOrderId].vue'),
        /* no children */
        meta: {
          "layout": "Event.layout",
          "public": true,
          "display_event_name": true
        }
      }
    ],
  },
  {
    path: '/login',
    name: '/login',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/login.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "title": "document.login",
      "b2b_help_chat": true,
      "public": true
    }
  },
  {
    path: '/logout',
    name: '/logout',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/logout.vue'),
    /* no children */
    meta: {
      "title": "public.user.logout.title",
      "public": true
    }
  },
  {
    path: '/organization/:organizationId',
    /* internal name: '/organization.[organizationId]' */
    /* no component */
    children: [
      {
        path: '',
        name: '/organization.[organizationId]/',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/organization.[organizationId]/index.vue'),
        /* no children */
        meta: {
          "allow_fundraising_auth": true
        }
      },
      {
        path: 'contacts',
        name: '/organization.[organizationId]/contacts',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/organization.[organizationId]/contacts.vue'),
        /* no children */
        meta: {
          "layout": "Organization.layout",
          "allow_fundraising_auth": true
        }
      },
      {
        path: 'teams',
        name: '/organization.[organizationId]/teams',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/organization.[organizationId]/teams.vue'),
        /* no children */
        meta: {
          "layout": "Organization.layout",
          "allow_fundraising_auth": true
        }
      }
    ],
  },
  {
    path: '/personal-settings',
    name: '/personal-settings',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/personal-settings.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "public": false,
      "protected_by_code": true,
      "title": "document.personal-settings"
    }
  },
  {
    path: '/register/:eventId/:organizationId?',
    name: '/register.[eventId].[[organizationId]]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/register.[eventId].[[organizationId]].vue'),
    /* no children */
    meta: {
      "layout": "Register.layout",
      "public": true,
      "display_event_name": true
    }
  },
  {
    path: '/register/confirmation/:orderId?/:registrationId',
    name: '/register.confirmation.[[orderId]].[registrationId]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/register.confirmation.[[orderId]].[registrationId].vue'),
    /* no children */
    meta: {
      "public": true
    }
  },
  {
    path: '/register/error/:registrationId?',
    name: '/register.error.[[registrationId]]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/register.error.[[registrationId]].vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "public": true,
      "display_event_name": true
    }
  },
  {
    path: '/register/fundraising/:fundraisingEventId?/:fundraisingTeamId?',
    name: '/register.fundraising.[[fundraisingEventId]].[[fundraisingTeamId]]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/register.fundraising.[[fundraisingEventId]].[[fundraisingTeamId]].vue'),
    /* no children */
    meta: {
      "layout": "Register.layout",
      "public": true,
      "display_event_name": true
    }
  },
  {
    path: '/reset-password',
    name: '/reset-password',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/reset-password.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "title": "document.reset-password",
      "allow_inactive_users": true,
      "public": false,
      "protected_by_code": true
    }
  },
  {
    path: '/sign-up',
    name: '/sign-up',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/sign-up.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "title": "document.signUp",
      "allow_inactive_users": true,
      "public": false,
      "protected_by_code": true
    }
  },
  {
    path: '/team-space/:organizationId/:eventId',
    /* internal name: '/team-space.[organizationId].[eventId]' */
    /* no component */
    children: [
      {
        path: '',
        name: '/team-space.[organizationId].[eventId]/',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/team-space.[organizationId].[eventId]/index.vue'),
        /* no children */
        meta: {
          "allow_fundraising_auth": true
        }
      },
      {
        path: 'dashboard',
        name: '/team-space.[organizationId].[eventId]/dashboard',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/team-space.[organizationId].[eventId]/dashboard.vue'),
        /* no children */
        meta: {
          "layout": "EventParticipation.layout",
          "allow_fundraising_auth": true
        }
      },
      {
        path: 'participants',
        name: '/team-space.[organizationId].[eventId]/participants',
        component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/team-space.[organizationId].[eventId]/participants.vue'),
        /* no children */
        meta: {
          "layout": "EventParticipation.layout",
          "allow_fundraising_auth": true
        }
      }
    ],
  },
  {
    path: '/team-space/fundraising/:fundraisingTeamId/:fundraisingEventId',
    name: '/team-space.fundraising.[fundraisingTeamId].[fundraisingEventId]',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/team-space.fundraising.[fundraisingTeamId].[fundraisingEventId].vue'),
    /* no children */
    meta: {
      "public": true,
      "allow_fundraising_auth": true
    }
  },
  {
    path: '/unsubscribe',
    name: '/unsubscribe',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/unsubscribe.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "public": true,
      "title": "document.email-preferences"
    }
  },
  {
    path: '/verification-by-code',
    name: '/verification-by-code',
    component: () => import('/opt/atlassian/pipelines/agent/build/next-frontend/src/pages/verification-by-code.vue'),
    /* no children */
    meta: {
      "layout": "Event.layout",
      "public": true,
      "title": "document.verification-by-code"
    }
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

